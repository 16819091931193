import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useStores() {
  const store = ref();
  const router = useRouter();

  const getStores = async (page) => {
    return await ApiService.query("otop_stores",page);
  };

  const getStore = async (id) => {
    let response = await ApiService.get("otop_stores/" + id);

    store.value = response.data.data;
  };

  const destroyStore = async (id) => {
    await ApiService.delete("otop_stores/" + id);
  };

  const stoerStore = async (data) => {
    await ApiService.post("otop_stores", data);
    await router.push({ name: "apps.travels.otop.store.index" });
  };

  const updateStore = async (data) => {
    await ApiService.put("otop_stores/" + data.id, data);
    await router.push({
      name: "apps.travels.otop.store.edit",
      params: { id: data.id },
    });
  };

  

  return {
    getStores,
    getStore,
    store,
    destroyStore,
    stoerStore,
    updateStore,
  };
}
